export default [
  {
    key: 'increasement',
    sortable: true,
    sortField: 'id',
    label: '#',
    stickyColumn: true,
    variant: 'light',
    hideToggle: true,
    thClass: 'w-50px text-center',
    tdClass: 'text-center',
  },
  {
    key: 'no',
    label: 'field.invoiceNo',
    hideToggle: true,
  },
  {
    key: 'payToName',
    subKey: 'payToVendorNo',
    label: 'field.vendorName',
    hideToggle: true,
  },
  {
    key: 'payToAddress',
    label: 'field.vendorAddress',
  },
  {
    key: 'postingDate',
    label: 'field.postingDate',
    type: 'date',
    hideToggle: true,
  },
  {
    key: 'vendorInvoiceNo',
    label: 'field.vendorInvoiceNo',
    hideToggle: true,
  },
  {
    key: 'amount',
    label: 'field.total',
    type: 'currency',
    hideToggle: true,
  },
  {
    key: 'amountIncludingVat',
    label: 'field.totalWithVat',
    type: 'currency',
    hideToggle: true,
  },
  {
    key: 'paymentMethod',
    label: 'field.paymentMethod',
    type: 'currency',
  },
  {
    key: 'status',
    label: 'field.status',
    thClass: 'text-center',
    tdClass: 'text-center',
    hideToggle: true,
  },
  {
    key: 'actions',
    label: 'field.action',
    hideToggle: true,
  },
];
