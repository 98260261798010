export default [
  {
    key: 'no',
    label: 'field.invoiceNo',
    type: 'text',
    operator: '%',
  },
  {
    key: 'postingDate',
    label: 'field.postingDate',
    type: 'date-range-picker',
    cast: 'range',
    firstOperator: 'd>=',
    secondOperator: 'd<=',
  },
  {
    key: 'status',
    label: 'field.status',
    selectionKey: 'value',
    selectionLabel: 'text',
    type: 'multi-selection',
    operator: 'in',
    translatable: false,
    options: [
      { text: 'Open', value: 'Open' },
      { text: 'Released', value: 'Released' },
      { text: 'Posted', value: 'Posted' },
    ],
  },
];
